.blog-title {
    text-align: left;
    margin-left: 100px;
    max-width: 300px;
    @media (max-width: 1025px) {
        margin-left: 40px;
    }
}

.blog-description {
    text-align: left;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 3px;
    margin-left: 102px;
    margin-right: 102px;
    @media (max-width: 1025px) {
        margin-left: 40px;
    }
}

.blog-heading-container {
    height: fit-content;
    animation: fadeInLeft 1s 0.5s both;
    margin-bottom: 0px;
    margin-top: 0px;
    border: 0.5px solid transparent;
}

.the-blog {
    max-width: 500px;
    margin-top: 200px;
    border-radius: 0 100px 100px 0;
    padding-top: 30px;
    padding-bottom: 20px;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
}