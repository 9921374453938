.news-card {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    @media (max-width: 1025px) {
        flex-direction: column;
      }
}

.news-image {
    width: 600px;
    @media (max-width: 1025px) {
        width: 360px;
      }
}

.news-info {
    width: 600px;
    margin-left: 30px;
    text-align: left;
    @media (max-width: 1025px) {
        width: 350px;
        margin-left: 20px;
      }
}

.news-date {
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
}

.news-name {
    margin-top: 0px;
}

.news-button {
    background: none;
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid black;
    padding: 10px;
}

.news-button:hover {
    cursor: pointer;
    background: #8c7184;
    border: 1px solid #8c7184;
    color: white;
}