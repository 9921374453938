@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300&display=swap');


.member-cards-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 6em;
    margin-left: 50px;
    max-width: 1100px;

    @media (max-width: 1025px) {
        flex-direction: column;
        margin-left: 40px;
        margin-top: 3em;
        max-width: 100vw;
    }
}

.member-card {
    width: 270px;
    margin: 20px;
}

.member-info {
    text-align: justify;
    text-justify: inter-word;
    margin-left: 10px;
    margin-right: 10px;

    .member-name {
        font-family: 'Red Hat Display', sans-serif;
        margin-bottom: 0;
    }
    
    .member-role {
        margin-top: 0;
    }
    
    .member-bio {
        font-size: 14px;
    }
}

.member-img {
    width: 100%;
}

.pronouns {
    font-size: 8px;
    color: rgb(98, 97, 97);
}
