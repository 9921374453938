.partner-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 6em;
    margin-left: 50px;
    max-width: 100vw;
    @media (max-width: 1025px) {
        margin-left: 10%;
        margin-top: 3em;
    }
}

.partner-card {
    width: fit-content;
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 40px;
    padding: 10px 20px 10px 20px;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.5);;

}

.partner-info {
    display: none;
}

.partner-link {
    text-decoration: none;
    color: black;
    font-size: 20px;
}


