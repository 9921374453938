.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.social-logo {
    width: 20px;
    margin: 10px;
}

.social-media {
    margin-top: -10px;
    margin-bottom: 10px;
}
