@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');
@font-face {
  font-family: 'CraftworkGrotesk';
  src: local('CraftworkGrotesk'),
  url('../../assets/fonts/CraftworkGrotesk-Regular.ttf');
}


.header {
  background-image: url("../../images/background-corals5.jpg");
  background-size: cover;
  background-position: center;
  @media (max-width: 1025px) {
    background-image: url("../../images/background-corals24.jpg");
  }
}

.header-content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
}
  
  .title {
    font-family: 'Comfortaa', cursive;
    font-weight: 100;
    margin-bottom: 0px;
    margin-top: 0px;
    background-clip: text;
    background: -webkit-radial-gradient(#d8bcb8, #666f69);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 1025px) {
      font-size: 80px;
    }
    @media screen and (min-width: 1025px) {
      font-size: 150px;  
    }
    @media screen and (min-width: 1750px) {
      font-size: 200px;
    }
  }

  .title-top {
    animation: fadeInRight 2s 0.5s both;
    text-align: left;
    margin-top: 10%;
    margin-left: 2%;
    @media (max-width: 1025px) {
      margin-top: 30%;
    }
  }

  .title-bottom {
    animation: fadeInLeft 2s 0.5s both;
    width: 96vw;
    text-align: end;
    @media (max-width: 1025px) {
      margin-top: 300px;
    }
  }




  .subtitle {
    font-family: 'CraftworkGrotesk';
    text-align: end;
    margin-right: 50px;
    margin-bottom: 120px;
    font-size: 35px;
    font-weight: 300;
    animation: fadeInLeft 2s 0.5s both;
    width: 96vw;
    color: #c6aba5;
    @media (max-width: 1025px) {
      font-size: 20px;
    }
  }


  .button-homepage {
    border: none;
    background-color: transparent;
    margin-right: 100px;
    font-size: 25px;
    font-weight: 300;
    margin-top: 10px;
    cursor: pointer;
  }

  .button-homepage2 {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    color: #c6aba5;
    animation: fadeInUp 2s 0.5s both;
    @media (min-width: 1025px) {
      display: none;
    }
  }

  .button-homepage:hover {
    color: #8c7184;
  }

 .section1-news {
    padding-top: 60px;
    padding-bottom: 120px;
 }