.arrow {
    width: 50px;
    cursor: pointer;
}

.testimonials-box {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    width: 500px;
    @media (max-width: 1025px) {
        width: 300px;
        margin-left: 16px;
    }
}

.arrows {
    display: flex;
    justify-content: space-between;
    border-radius: 25px;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
}
