@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height:100vh;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
}


.contact-form {
    text-align: left;

    @media (max-width: 1025px) {
        width: 300px;
    }

    @media (min-width:1025px) {
        margin-right: 5em;
        margin-left: 7em;
        width: 700px;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            animation: fadeInDown 1s 0.5s both;
        }
    }
}

input[type='text'],
input[type='email'] {
        width: 100%;
        border: 0;
        background: rgb(240, 238, 238);
        height: 50px;
        font-size: 16px;
        padding: 0 20px;
        box-sizing: border-box;
}

input:focus::placeholder {
    color: transparent;
   }
  
input::placeholder {
    color: #494f59;
    transition: color 0.3s ease;
   }

   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
   }

   input:focus, textarea:focus, select:focus{
       outline: none;
   }

textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
    width: 100%;
    border: 0;
    background: rgb(240, 238, 238);
    height: 150px;
    padding: 20px;
    min-height: 50px;
    box-sizing: border-box;
    resize: none;
    @media (max-width: 1025px) {
        height: 100px;
    }
}

textarea:focus::placeholder {
    color: transparent;
   }
  
textarea::placeholder {
    color: #494f59;
    transition: color 0.3s ease;
   }

.button {
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #5e4c49;
    color: #5e4c49;
    float: right;
    cursor: pointer;
    transition: all .3s;
}

.contact-me {
    font-size: 30px;
    font-family: 'Comfortaa', cursive;
    margin-bottom: 0;
    @media (max-width: 1025px) {
        font-size: 20px;
        margin-top: 4em;
    }
}

.contact-text {
    animation: fadeInDown 1s 0.5s both;
}

p.contact-text {
    @media (max-width: 1025px) {
        font-size: 14px;
    }
}

.status-message {
    color: #f0745e;
    margin-left: 0;
    margin-top: 0;
    font-size: 16px;
}