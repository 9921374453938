

.nav-bar {
    margin-top: 7px;
    padding-top: 8px;
    padding-bottom: 10px;
    overflow: visible;
    list-style: none;
    height: 36px;
    z-index: 100;
    width: 100vw;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 50px;
    margin-left: 20px;
    margin-top: 10px;
  }

  .logo:hover {
    animation: pulse 1s 0.5s both;
  }

  .nav-link {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 20px;
    margin-left: 20px;
    @media (max-width: 1025px) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }


  .selected {
    font-weight: 800;
  }

  .nav-links {
    border-radius: 25px;
    padding: 10px;
    margin-right: 10px;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
  }

  ul {
    list-style: none;
  }

  ul li{
    display:inline-block; 
    padding:0 7px; 
    position:relative;
  }

