.testimonial {
    display: flex;
}

.quote {
    text-align: justify;
    text-justify: inter-character;
}

.testimonial-name {
    text-align: left;
    color: rgb(98, 97, 97);
}

.testimonial {
    height: 330px;
    @media (max-width: 1025px) {
        height: auto;
    }
}