@font-face {
    font-family: 'CraftworkGrotesk';
    src: local('CraftworkGrotesk'),
    url('../../assets/fonts/CraftworkGrotesk-Regular.ttf');
  }

.nav-backround {
    height: 60px;
}

.news-info-container {
    display: flex;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
    margin-left: 20px;
    margin-right: 20px;
    @media (max-width: 1025px) {
        flex-direction: column;
      }
}

.news-heading-container {
    width: 800px;
    text-align: justify;
    margin-right: 60px;
    @media (max-width: 1025px) {
        width: 350px;
        margin-left: 20px;
        margin-right: 20px;
      }
}

.news-info-description {
    font-family: 'CraftworkGrotesk';
    font-size: 40px;
    font-weight: 200;
    @media (max-width: 1025px) {
        font-size: 30px;
      }
}

.news-details {
    font-size: 22px;
    font-weight: 300;
}

.news-link {
    text-decoration: none;
    font-size: 22px;
    font-weight: 600;
    color: black;
    opacity: 0.5;
    cursor: pointer;
}

.news-link-container {
    margin-top: 60px;
}

.news-image2 {
    width: 500px;
    @media (max-width: 1025px) {
        width: 360px;
        margin-top: 60px;
        margin-bottom: 120px;
      }
}

.news-title {
    font-size: 40px;
    text-transform: uppercase;
}