.posts-container {
    margin-top: 100px;
    margin-left: 100px;
    display: flex;
    @media (max-width: 1025px) {
        flex-direction: column;
        margin-left: 20px;
    }
}

.post-card {
    width: 356px;
    height: 356px;
    margin-right: 50px;
    margin-bottom: 50px;
    background-size: cover;
    color: white;
    animation: fadeInUp 2s 0.5s both;
}

.post-info {
    background: rgba(3, 3, 3, 0.5);
    padding-top: 5px;
    padding-bottom: 5px;
}

.post-description {
    font-size: 15px;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
}

.post-link {
    text-decoration: none;
}