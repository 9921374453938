@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;700&display=swap');
@font-face {
    font-family: 'CraftworkGrotesk';
    src: local('CraftworkGrotesk'),
    url('../../assets/fonts/CraftworkGrotesk-Regular.ttf');
  }


.about-page {
    top: 0;
    left: 0;
    max-width: 100vw;
}

.manifesto-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    height: fit-content;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
    @media (max-width: 1025px) {
        flex-direction: column;
    }
}

.scroll-down {
    margin-top: 15em;
    @media (max-width: 1025px) {
        display: none;
    }
}

.team-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    margin-top: 10em;
    @media (max-width: 1025px) {
        flex-direction: column;
        margin-top: 10em;
    }
}

.partners-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 10em;
    padding-bottom: 10em;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
    @media (max-width: 1025px) {
        flex-direction: column;
        padding-top: 10em;
        padding-bottom: 10em;
    }
}

.category-title {
    font-family: 'CraftworkGrotesk';
    text-transform: uppercase;
    font-weight: 100;
    font-size: 46px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: end;
    @media (max-width: 1025px) {
        width: 300px;
        font-size: 30px;
        text-align: left;
        margin-left: 40px;
    }
}

.about-category-title {
    width: 500px;
    @media (max-width: 1025px) {
        max-width: 300px;
        margin-left: 40px;
        text-align: center;
    }
}

.manifesto-title {
    margin-top: 4em;
    animation: fadeInLeft 1s 0.5s both;
}

.manifesto {
    text-align: justify;
    text-justify: inter-word;
    font-size: 18px;
    width: 60%;
    margin-bottom: 6em;
    margin-top: 10em;
    margin-left: 50px;
    animation: fadeInRight 1s 0.5s both;
    @media (max-width: 1025px) {
        margin-left: 10%;
        width: 80%;
        margin-top: 3em;
    }
}

.manifesto a {
    color: rgb(98, 97, 97);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.manifesto a:hover {
    color: rgb(255, 255, 255);
}

.testimonials-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    height: fit-content;
    padding-top: 10em;
    padding-bottom: 10em;
    padding-right: 20em;
    @media (max-width: 1025px) {
        flex-direction: column;
        padding-left: 2em;
        padding-right: 2em;
    }
}

.testimonials-title {
    @media (max-width: 1025px) {
        margin: 0px;
        margin-bottom: 2em;
    }
}