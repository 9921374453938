.spotlight-title {
    padding-top: 4.5em;
}

.spotlight-container {
    height: fit-content;
    padding-bottom: 4.5em;
    background-image: url("../../images/background2.jpg");
    background-size: cover;
    background-position: center;
    height: 80vh;
}